import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from "@angular/router";
import { HttpParams } from '@angular/common/http';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { Ng5SliderModule } from 'ng5-slider';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, RemoveUnderscorePipe } from './app.component';
import { SearchComponent } from './search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthorizationComponent } from './authorization/authorization.component';
import { HomeComponent } from './home/home.component';
import { AutocompleteComponent } from './google-places.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthGuard } from './auth.guard.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsofuseComponent } from './termsofuse/termsofuse.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ResetNewpasswordComponent } from './resetnewpassword/resetnewpassword.component';
import { RegistrationComponent } from './registration/registration.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { UserProfileComponent } from './userprofile/userprofile.component';
import { activatelinkComponent } from './activatelink/activatelink.component';
import { DashboardComponent, SafeHtmlPipe } from './dashboard/dashboard.component';
import { MatMenuModule } from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import { UserportfolioComponent } from './userportfolio/userportfolio.component';

const routes: Routes = [];
let paramValue;
const url = window.location.href;
if (url.includes('?')) {
  const httpParams = new HttpParams({ fromString: url.split('?')[1] });
  paramValue = httpParams.get('RequestID');
}
if(!paramValue){
  routes.push(
    { path: "", redirectTo: "landing", pathMatch: "full" },
    { path: "landing", component: LandingComponent }
  )
}
@NgModule({
  declarations: [
    AutocompleteComponent,
    AppComponent,
    RemoveUnderscorePipe,
    SearchComponent,
    AuthorizationComponent,
    HomeComponent,
    LoaderComponent,
    ForgotpasswordComponent,
    ContactusComponent,
    AboutusComponent,
    PrivacyComponent,
    TermsofuseComponent,
    PricingComponent,
    FaqComponent,
    ResetpasswordComponent,
    ResetNewpasswordComponent,
    RegistrationComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    UserProfileComponent,
    activatelinkComponent,
    DashboardComponent,
    SafeHtmlPipe,
    UserportfolioComponent    
  ], 
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatChipsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    NgxScrollTopModule,
    AutocompleteLibModule,
    RecaptchaModule.forRoot({
     //siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', //localhost site key
     siteKey: '6LcCguYUAAAAAIlY1ArnagnGrEvpDILPfY8DliGP', //landchecks site key
    }),
    Ng5SliderModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatTabsModule
  ],
  entryComponents:[LoaderComponent],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {
  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
 }
