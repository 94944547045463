import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { IpService } from './ip.service';

@Injectable()
export class AuthGuard implements CanActivate {
  userIp: string = '';

  constructor(private _router: Router,private ipService: IpService) {
    
  }
  ngOnInit(): void {
    this.getUserIp();
  }
  getUserIp(): void {
    this.ipService.getIpAddress().subscribe((data: any) => {
      this.userIp = data.ip;
      console.log('User IP Address: ', this.userIp);
    });
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (window.sessionStorage.getItem('userToken') != null) {
        return true;
    }

    // navigate to login page
    //this._router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    //return false;
    // check if user quota is exhausted

    return true;
  }
}