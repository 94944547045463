import { Component, OnInit } from '@angular/core';
import { LandChecksService } from '../landchecks.service';

@Component({
  selector: 'app-activatelink',
  templateUrl: './activatelink.component.html',
  styleUrls: ['./activatelink.component.scss']
})
export class activatelinkComponent implements OnInit {

  userId:string;
  error: boolean = false;
  success: boolean = false;
  message: string = '';
  
  constructor(private landChecksService: LandChecksService) { }

  ngOnInit() {
    const url = window.location.href;
    
    var values = url.split('.');
    //user id is last but one value in the string separated by dot .
    this.userId = values[values.length-2];
    
    this.landChecksService.showLoader(true);
    let data =
    {
      userId: this.userId
    };
    this.landChecksService.activateUserLink(data).subscribe((result: any) => {
      if (result && result != null) {
        if (result.IsSuccess) {
          this.success = true;
          this.message = "Your landchecks user account has been activated successfully. Please click on <a href='/login'>LOGIN</a> to proceed.";
        }
        else {
          this.error = true;
          this.message = "Something went wrong.";
        }
      }
      else {
      }
      this.landChecksService.showLoader(false);
    });
  }


}