import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-termsofuse',
  templateUrl: './termsofuse.component.html',
  styleUrls: ['./termsofuse.component.scss']
})
export class TermsofuseComponent implements OnInit {

  isVendor:boolean = false;
  constructor() {
    if (window.sessionStorage.getItem('vname') != null && window.sessionStorage.getItem('vname') != '' &&
      window.sessionStorage.getItem('vname') != undefined) {
        this.isVendor = true;
      }
  }

  ngOnInit() {
  }
  viewCategories(){
    window.location.href='/home';
  }
}
