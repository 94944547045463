import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LandChecksService } from '../landchecks.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  error: boolean = false;
  message: string = "";
  isVendor: boolean = false;
  LoginForm = new FormGroup({
    UserName: new FormControl('', Validators.required),
    Password: new FormControl('', Validators.required),
  });

  constructor(private router: Router, private landChecksService: LandChecksService) {
    window.sessionStorage.clear();
    this.isVendor = false;
  }

  ngOnInit() {
    if (window.sessionStorage.getItem('userToken') != null) {
      this.router.navigate(['/home']);
    }
  }

  OnGetToken() {
    this.landChecksService.showLoader(true);
    const user = this.LoginForm.controls['UserName'].value;
    const pass = this.LoginForm.controls['Password'].value;
    this.landChecksService.userAuthentication(user, pass).subscribe(data => {
      debugger;
      if (data.userActivated == "True"){
        window.sessionStorage.setItem('userToken', data.access_token);
        window.sessionStorage.setItem('userId', data.userId);
        window.sessionStorage.setItem('uname', user);
        window.sessionStorage.setItem('state', 'USA');
        this.landChecksService.showLoader(false);
        //this.router.navigate(['/home']);
        window.location.href = '/home';
      }
      else
      {
          debugger;
          this.landChecksService.showLoader(false);
          this.error = true;
          this.message = "User Account Not Activated !!!";
        
      }
    },
      error => {
        debugger;
        this.landChecksService.showLoader(false);
        this.error = true;
        this.message = "Incorrect Username or Password !!!";
      });
  }
}
